<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="10">
            <video-player :options="videoOptions"></video-player>
          </v-col>
        </v-row>
        <v-row>
          <div
            style="width: 140px"
            class="mx-3"
            v-for="(item, i) in videoDetail"
            :key="i"
          >
            <img
              :src="getImgUrl(item)"
              alt=""
              width="100%"
              :class="item.selected === false ? 'py-5' : ''"
              style="cursor: pointer"
              @click="selectVideo(item)"
            />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { API_VIDEOSECTION_DETAIL } from "@/constants/APIUrls";
export default {
  name: "VideoSectionPeteacher",
  components: {
    videoPlayer: () => import("@/components/VideoPlayer/VideoPlayerPeTeacher"),
  },
  data() {
    return {
      videoDetail: [],
      videoDetailCopy: [],
      videoOptions: {
        autoplay: false,
        controls: true,
        width: "950",
        poster: "",
        sources: [
          {
            src: "",
            type: "video/mp4",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getImgUrl(item) {
      if (item.selected) {
        return item.active_thumbnail;
      } else {
        return item.thumbnail;
      }
    },
    selectVideo(item) {
      const sequence = item.sequence;
      const preIndex = this.videoDetail.findIndex((i) => i.selected === true);
      this.videoDetail[preIndex].selected = false;

      const index = this.videoDetail.findIndex((i) => i.sequence === sequence);
      this.videoDetail[index].selected = true;
      this.videoOptions.sources[0].src = item.video_url;
      this.videoOptions.poster = item.video_thumbnail
      
    },
    getVideoDetail() {
      const successHandler = (res) => {
        this.videoDetail = res.data.wizfit_video_list;
        this.videoDetailCopy = res.data.wizfit_video_list;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.setIntialState();
      };
      let formData = {};
      Axios.request_GET(
        API_VIDEOSECTION_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    setIntialState() {
      this.videoDetail[0].selected = true;
      this.videoOptions.sources[0].src = this.videoDetail[0].video_url;
      this.videoOptions.poster = this.videoDetail[0].video_thumbnail;
    },
  },
  mounted() {
    this.getVideoDetail();
  },
};
</script>
